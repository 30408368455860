@charset "UTF-8";
/* MOBILE QUERIES */
/* MENU MAIN + MENU HEAD */
/* MENU MOBILE */
/* FULLSCREEN MENU - ako go polzvame + lang vars */
/* BANNER ROTATOR TITLE */
/* HEADER VARS */
/* FOOTER */
/* Kareta 1 */
/* Kareta 2  */
/* Kareta 3 */
/* Filter by years */
/* Breadcrumbs */
/* Current Page Text - all pages */
/* Page Content */
/* Page Gallery */
/* Page Contact */
/* CKEditor Custom Styles */
/* slider index
@import "_slider.scss";*/
*:focus {
  outline: 0; }

input, textarea, keygen, select, button {
  font-family: "Cormorant", serif; }

.modal {
  z-index: 99999 !important; }

a {
  transition: color 0.3s ease; }

/* html, body, wrappers */
html {
  -webkit-font-smoothing: antialiased; }

body {
  color: #333;
  /* background-color: $default-body-bgcolor;*/
  font-family: "Cormorant", serif;
  box-sizing: border-box;
  /* KOGATO IMAME FULL SCREEN MENU!!! */ }
  body.overflow-hidden {
    /* when primary navigation is visible, the content in the background won't scroll */
    overflow: hidden; }

html, body {
  margin: 0;
  padding: 0; }

.main-wrapper {
  clear: both;
  background-color: #fff;
  max-width: 100%; }

.wrapper {
  max-width: 1230px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px; }

/* Main Menu */
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9998;
  background-color: rgba(255, 255, 255, 0.95); }

/*  xs */
@media (max-width: 767px) {
  header {
    height: 50px; } }

.fixedMenuLarge {
  transition: background 0.1s ease;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 9998;
  /* languages lg dropdown */
  /* MAIN Navigation */ }
  .fixedMenuLarge div.contactus-btn {
    position: absolute;
    top: 0;
    right: 90px;
    /*
    transition: all 0.3s ease;
    */ }
    .fixedMenuLarge div.contactus-btn a {
      display: block;
      width: 30px;
      height: 50px;
      background: transparent url("../img/booknow-up.png") no-repeat bottom 14px center;
      transition: none !important; }
      .fixedMenuLarge div.contactus-btn a span {
        display: none; }
      .fixedMenuLarge div.contactus-btn a:hover {
        background: transparent url("../img/booknow-down.png") no-repeat bottom 14px center; }
  .fixedMenuLarge div.logo-fixed-big {
    position: absolute;
    width: 245px;
    top: -100px;
    left: 50%;
    /*    right: 0;
    margin-left: auto;
    margin-right: auto;*/
    opacity: 0;
    transition: all 0.3s ease; }
  .fixedMenuLarge div.logo-fixed {
    position: absolute;
    top: 8px;
    width: 208px;
    left: 20px;
    z-index: 9998;
    opacity: 1;
    transition: all 0.3s ease; }
  .fixedMenuLarge div.menu-button {
    position: absolute;
    right: 20px;
    top: 7px;
    width: 45px;
    z-index: 9998;
    text-align: right;
    display: block; }
    .fixedMenuLarge div.menu-button button {
      display: inline-block;
      text-align: center;
      background-color: transparent;
      transition: 0.3s ease background-color;
      border: none;
      background: none; }
    .fixedMenuLarge div.menu-button .menu-txt {
      display: inline-block;
      font-family: "Cormorant", serif;
      font-size: 17px;
      height: 26px;
      color: #555555;
      width: 100%;
      font-weight: 700;
      text-align: center;
      border-bottom: 4px solid #555555;
      transition: 0.3s ease border-color; }
      .fixedMenuLarge div.menu-button .menu-txt:hover {
        border-color: #ff0007; }
  .fixedMenuLarge div#Languages-lg {
    transition: all 0.3s ease;
    position: absolute;
    top: 16px;
    right: 148px;
    z-index: 9999;
    font-weight: 700;
    text-align: center; }
    .fixedMenuLarge div#Languages-lg .btn {
      background-color: transparent;
      padding: 0 0 10px 5px;
      font-family: "Cormorant", serif;
      font-size: 16px;
      line-height: 21px;
      color: #ab9065;
      font-weight: 700; }
      .fixedMenuLarge div#Languages-lg .btn span {
        display: inline-block;
        width: 9px;
        height: 12px;
        color: transparent; }
      .fixedMenuLarge div#Languages-lg .btn:before {
        content: '»';
        position: absolute;
        top: 0;
        left: -8px;
        color: rgba(255, 255, 255, 0.3);
        font-size: 18px;
        font-weight: 400; }
    .fixedMenuLarge div#Languages-lg ul {
      padding: 12px 10px 15px 10px;
      margin-left: -12px;
      margin-top: -10px;
      background-color: transparent;
      min-width: 60px;
      border: 0;
      box-shadow: none; }
      .fixedMenuLarge div#Languages-lg ul li {
        padding: 0; }
        .fixedMenuLarge div#Languages-lg ul li a {
          font-family: "Cormorant", serif;
          font-size: 16px;
          line-height: 21px;
          color: #002442;
          text-decoration: none;
          font-weight: 700;
          padding: 0 0 0 9px;
          background-color: #fff;
          margin-top: 2px; }
          .fixedMenuLarge div#Languages-lg ul li a:hover {
            color: #ffffff;
            text-decoration: none; }
          .fixedMenuLarge div#Languages-lg ul li a.active {
            color: #ffffff;
            text-decoration: none; }
  .fixedMenuLarge nav#mainNav {
    transition: all 0.3s ease;
    position: absolute;
    right: 245px;
    top: 25px;
    z-index: 9998;
    text-align: right;
    font-family: "Cormorant", serif;
    /* main Navigation Dropdown menu with styled bootstrap dropdown */ }
    .fixedMenuLarge nav#mainNav > ul {
      padding: 0;
      margin: 0; }
      .fixedMenuLarge nav#mainNav > ul > li {
        display: inline-block;
        text-align: center;
        padding: 0 1px;
        width: auto;
        vertical-align: top;
        position: relative; }
        .fixedMenuLarge nav#mainNav > ul > li:last-of-type {
          padding-right: 0; }
        .fixedMenuLarge nav#mainNav > ul > li > a {
          font-size: 16px;
          font-weight: 700;
          color: #ffffff;
          text-transform: uppercase;
          text-decoration: none;
          padding: 0 15px;
          padding-bottom: 26px; }
          .fixedMenuLarge nav#mainNav > ul > li > a:hover, .fixedMenuLarge nav#mainNav > ul > li > a.active {
            color: #ab9065;
            text-decoration: none; }
        .fixedMenuLarge nav#mainNav > ul > li:hover .dropdown-menu {
          display: block;
          margin-top: 10px; }
        .fixedMenuLarge nav#mainNav > ul > li.active > a:after {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 6px 0 6px;
          border-color: #ff6c00 transparent transparent transparent;
          bottom: -10px;
          content: " ";
          left: 50%;
          margin-left: -6px;
          position: absolute; }
        .fixedMenuLarge nav#mainNav > ul > li:hover > a:after {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 6px 0 6px;
          border-color: #fff transparent transparent transparent;
          bottom: -10px;
          content: " ";
          left: 50%;
          margin-left: -6px;
          position: absolute; }
    .fixedMenuLarge nav#mainNav .dropdown-menu {
      box-shadow: none;
      border: 0;
      padding-bottom: 15px;
      z-index: 9999;
      top: 23px;
      text-align: center;
      margin-top: 10px;
      background-color: transparent;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%); }
      .fixedMenuLarge nav#mainNav .dropdown-menu > li {
        transition: all 0.3s ease;
        background-color: rgba(255, 255, 255, 0.9); }
        .fixedMenuLarge nav#mainNav .dropdown-menu > li:first-of-type {
          margin-top: 10px; }
        .fixedMenuLarge nav#mainNav .dropdown-menu > li:hover {
          background-color: white; }
        .fixedMenuLarge nav#mainNav .dropdown-menu > li a {
          text-align: center;
          font-size: 16px;
          line-height: 24px;
          text-transform: none;
          font-weight: 700;
          padding: 0 27px 0 27px;
          color: #ffffff; }
          .fixedMenuLarge nav#mainNav .dropdown-menu > li a:hover {
            color: #ab9065;
            background-color: transparent; }
  .fixedMenuLarge .dropdown:hover .dropdown-menu {
    display: block; }
  .fixedMenuLarge .dropdown-menu {
    margin-top: 0px; }

.cd-primary-nav {
  visibility: hidden;
  z-index: -9999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*  transform: translateY(-100%);
    transition-property: transform;*/
  transition: background 0.3s ease;
  opacity: 0.1;
  overflow-y: auto; }

.cd-primary-nav.is-visible {
  z-index: 10001;
  visibility: visible;
  opacity: 1;
  /*  transform: translateY(0);*/
  background-color: rgba(255, 255, 255, 0.95); }

.cd-primary-nav {
  padding-top: 50px;
  padding-bottom: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center; }
  .cd-primary-nav ul.fullscreenmenu {
    margin-top: 0;
    margin-bottom: 0; }
    .cd-primary-nav ul.fullscreenmenu li {
      text-align: center;
      padding: 5px 0; }
      .cd-primary-nav ul.fullscreenmenu li:last-of-type {
        border-bottom: 0; }
      .cd-primary-nav ul.fullscreenmenu li a {
        font-family: "Cormorant", serif;
        font-size: 30px;
        font-weight: 700;
        line-height: 60px;
        color: #555555;
        text-transform: uppercase;
        text-align: center; }
        .cd-primary-nav ul.fullscreenmenu li a:hover, .cd-primary-nav ul.fullscreenmenu li a.active {
          color: #bb0005; }
      .cd-primary-nav ul.fullscreenmenu li ul {
        margin-top: 5px;
        margin-bottom: 0; }
        .cd-primary-nav ul.fullscreenmenu li ul li {
          padding: 5px 0;
          border-bottom: 0; }
          .cd-primary-nav ul.fullscreenmenu li ul li a {
            font-family: "Cormorant", serif;
            font-size: 26px;
            font-weight: 700;
            color: #555555; }
            .cd-primary-nav ul.fullscreenmenu li ul li a:hover, .cd-primary-nav ul.fullscreenmenu li ul li a.active {
              color: #bb0005;
              text-decoration: none; }
  .cd-primary-nav ul.fullscreenmenu-header {
    margin-top: 60px;
    margin-bottom: 0;
    text-align: center; }
    .cd-primary-nav ul.fullscreenmenu-header li {
      display: inline-block;
      text-align: center;
      padding: 10px 20px; }
      .cd-primary-nav ul.fullscreenmenu-header li:last-of-type {
        border-bottom: 0; }
      .cd-primary-nav ul.fullscreenmenu-header li a {
        font-family: "Cormorant", serif;
        font-size: 26px;
        font-weight: 700;
        letter-spacing: 0.01em;
        color: #555555;
        text-align: center; }
        .cd-primary-nav ul.fullscreenmenu-header li a:hover, .cd-primary-nav ul.fullscreenmenu-header li a.active {
          color: #bb0005; }
  .cd-primary-nav div#Languages {
    top: inherit;
    margin-top: 30px;
    position: relative;
    width: 100%;
    text-align: center; }
    .cd-primary-nav div#Languages ul {
      margin-bottom: 70px; }
      .cd-primary-nav div#Languages ul li {
        display: inline-block;
        padding: 0 20px; }
        .cd-primary-nav div#Languages ul li a {
          display: inline-block;
          font-family: "Cormorant", serif;
          font-size: 28px;
          font-weight: 700;
          color: #555555;
          text-transform: capitalize;
          width: 75px;
          height: 75px;
          line-height: 75px;
          border: 1px solid #d5d3d1; }
          .cd-primary-nav div#Languages ul li a:hover, .cd-primary-nav div#Languages ul li a.active {
            color: #ffffff;
            text-decoration: none;
            background-color: #bb0005;
            border: 1px solid #bb0005; }
        .cd-primary-nav div#Languages ul li:last-of-type {
          border-right: 0; }
  .cd-primary-nav .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9999; }
    .cd-primary-nav .close-btn button {
      border: none;
      border: 0;
      border-width: 0;
      width: 50px;
      height: 50px;
      background: #bb0005 url("../img/closebtn.png") no-repeat center center; }

/*  xs */
@media (max-width: 767px) {
  .cd-primary-nav {
    display: inherit;
    -ms-flex-direction: inherit;
    flex-direction: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit;
    padding-top: 30px; }
    .cd-primary-nav ul.fullscreenmenu {
      margin-top: 50px; }
      .cd-primary-nav ul.fullscreenmenu li {
        padding: 5px 0; }
        .cd-primary-nav ul.fullscreenmenu li a {
          font-size: 26px;
          line-height: 48px; }
        .cd-primary-nav ul.fullscreenmenu li ul {
          margin-top: 15px;
          margin-bottom: 0; }
          .cd-primary-nav ul.fullscreenmenu li ul li {
            padding: 5px 0;
            border-bottom: 0; }
            .cd-primary-nav ul.fullscreenmenu li ul li a {
              font-size: 15px; }
    .cd-primary-nav ul.fullscreenmenu-header {
      margin-top: 25px;
      margin-bottom: 10px;
      text-align: center; }
      .cd-primary-nav ul.fullscreenmenu-header li {
        display: block;
        text-align: center;
        padding: 6px 10px; }
        .cd-primary-nav ul.fullscreenmenu-header li a {
          font-size: 16px;
          font-weight: 400; }
    .cd-primary-nav div#Languages {
      text-align: center; }
      .cd-primary-nav div#Languages ul {
        margin-bottom: 25px; }
        .cd-primary-nav div#Languages ul li {
          padding: 0 15px;
          height: 30px; }
          .cd-primary-nav div#Languages ul li a {
            font-size: 18px;
            font-weight: 400;
            width: 50px;
            height: 50px;
            line-height: 50px; }
  .fixedMenuLarge {
    height: 50px; }
  .noFixedMenu {
    top: 0px; } }

/*  sm */
@media (min-width: 767px) and (max-width: 991px) {
  .cd-primary-nav {
    /*    display: inherit;
        flex-direction: inherit;
        justify-content: inherit;
        padding-top: 70px;*/ }
    .cd-primary-nav ul.fullscreenmenu {
      margin-top: 200px; }
      .cd-primary-nav ul.fullscreenmenu li {
        padding: 5px 0; }
        .cd-primary-nav ul.fullscreenmenu li a {
          font-size: 26px;
          line-height: 48px; }
        .cd-primary-nav ul.fullscreenmenu li ul {
          margin-top: 15px;
          margin-bottom: 0; }
          .cd-primary-nav ul.fullscreenmenu li ul li {
            padding: 5px 0;
            border-bottom: 0; }
            .cd-primary-nav ul.fullscreenmenu li ul li a {
              font-size: 15px; }
    .cd-primary-nav ul.fullscreenmenu-header {
      margin-top: 25px;
      margin-bottom: 10px;
      text-align: center; }
      .cd-primary-nav ul.fullscreenmenu-header li {
        display: block;
        text-align: center;
        padding: 6px 10px; }
        .cd-primary-nav ul.fullscreenmenu-header li a {
          font-size: 16px;
          font-weight: 400; }
    .cd-primary-nav div#Languages {
      text-align: center; }
      .cd-primary-nav div#Languages ul {
        margin-bottom: 25px; }
        .cd-primary-nav div#Languages ul li {
          padding: 0 15px;
          height: 30px; }
          .cd-primary-nav div#Languages ul li a {
            font-size: 18px;
            font-weight: 400;
            width: 50px;
            height: 50px;
            line-height: 50px; } }

/*  md */
@media (min-width: 992px) and (max-width: 1199px) {
  .cd-primary-nav {
    /*    display: inherit;
        flex-direction: inherit;
        justify-content: inherit;
        padding-top: 70px;*/ }
    .cd-primary-nav ul.fullscreenmenu {
      margin-top: 150px; }
    .cd-primary-nav ul.fullscreenmenu-header {
      margin-top: 25px;
      margin-bottom: 10px; } }

/*  lg > 1200 */
/*  lg > 1600 */
@media (max-width: 1200px) {
  .bottomslider-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%; }
  .welcome-div-inner {
    background-color: #fff;
    text-align: left;
    z-index: 9997;
    padding-left: 25px; }
    .welcome-div-inner a {
      background-color: #fff;
      height: 50px;
      z-index: 9997;
      padding-left: 0;
      font-family: "Cormorant", serif;
      font-size: 24px;
      line-height: 50px;
      color: #555555;
      font-weight: 400;
      font-style: italic; } }

/*  > xs */
@media (min-width: 1200px) {
  .bottomslider-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%; }
  .welcome-div {
    background-color: #fff;
    height: 70px;
    text-align: left;
    z-index: 9997;
    padding-left: 25px;
    font-family: "Cormorant", serif;
    font-size: 30px;
    line-height: 70px;
    color: #555555;
    font-weight: 400;
    font-style: italic; }
    .welcome-div a {
      background-color: #fff;
      height: 70px;
      text-align: left;
      z-index: 9997;
      padding-left: 25px;
      font-family: "Cormorant", serif;
      font-size: 30px;
      line-height: 70px;
      color: #555555;
      font-weight: 400;
      font-style: italic; }
  .welcome-div-inner {
    background-color: #fff;
    height: 70px;
    text-align: left;
    z-index: 9997;
    padding-left: 25px;
    font-family: "Cormorant", serif;
    color: #555555;
    font-weight: 400;
    font-style: italic; }
    .welcome-div-inner a {
      background-color: #fff;
      z-index: 9997;
      padding-left: 0;
      font-size: 24px;
      line-height: 70px;
      font-weight: 400;
      color: #555555;
      font-style: italic; }
  .reserv-div {
    position: relative;
    text-align: right;
    height: 70px; }
  div.contactus-btn-big {
    position: absolute;
    bottom: 21px;
    right: 0;
    width: 225px;
    height: 30px;
    z-index: 9997; }
    div.contactus-btn-big a {
      display: block;
      height: 100%;
      background: transparent url("../img/booknow-up-lg.png") no-repeat bottom 5px left; }
      div.contactus-btn-big a:hover {
        background: transparent url("../img/booknow-down-lg.png") no-repeat bottom 5px left; }
      div.contactus-btn-big a span {
        display: block;
        position: absolute;
        bottom: 0;
        left: 55px;
        text-align: left;
        font-family: "Cormorant", serif;
        font-size: 24px;
        line-height: 24px;
        text-transform: uppercase;
        font-weight: 700;
        color: #ffffff; }
  .noFixedMenu {
    height: 125px;
    background-color: transparent;
    /* languages lg dropdown */ }
    .noFixedMenu div.contactus-btn {
      display: none; }
    .noFixedMenu div.logo-fixed {
      top: -40px;
      opacity: 0; }
    .noFixedMenu div.logo-fixed-big {
      position: absolute;
      opacity: 1;
      top: 29px;
      width: 245px;
      left: 50%;
      /*      right: 0;
      margin-left: auto;
      margin-right: auto;*/ }
    .noFixedMenu div.menu-button {
      position: absolute;
      right: 45px;
      top: 35px;
      width: 45px; }
      .noFixedMenu div.menu-button button {
        display: block;
        text-align: center;
        background-color: transparent;
        transition: 0.3s ease background-color; }
      .noFixedMenu div.menu-button .menu-txt {
        display: inline-block;
        font-family: "Cormorant", serif;
        font-size: 17px;
        height: 29px;
        color: #ffffff;
        width: 100%;
        font-weight: 700;
        text-align: center;
        border-bottom: 5px solid #fff;
        transition: 0.3s ease border-color; }
        .noFixedMenu div.menu-button .menu-txt:hover {
          border-color: #ff0007; }
    .noFixedMenu div#Languages-lg {
      top: 45px;
      right: 166px;
      z-index: 9999; }
      .noFixedMenu div#Languages-lg .btn {
        font-size: 18px; }
    .noFixedMenu nav#mainNav {
      right: 265px;
      top: 54px;
      /* main Navigation Dropdown menu with styled bootstrap dropdown */ }
      .noFixedMenu nav#mainNav > ul > li > a {
        font-size: 18px;
        padding: 0 17px;
        padding-bottom: 55px; }
      .noFixedMenu nav#mainNav > ul > li.active > a:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: #ff6c00 transparent transparent transparent;
        bottom: -16px;
        content: " ";
        left: 50%;
        margin-left: -10px;
        position: absolute; }
      .noFixedMenu nav#mainNav > ul > li:hover > a:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: #fff transparent transparent transparent;
        bottom: -16px;
        content: " ";
        left: 50%;
        margin-left: -10px;
        position: absolute; }
      .noFixedMenu nav#mainNav .dropdown-menu {
        top: 35px; } }

@media only screen and (max-height: 500px) {
  .cd-primary-nav {
    padding-top: 50px; }
    .cd-primary-nav ul.fullscreenmenu li ul {
      display: none; } }

/* Index */
section#welcome {
  overflow: hidden; }
  section#welcome .eqWrap {
    display: -ms-flexbox;
    display: flex; }
  section#welcome .equalHMWrap {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  section#welcome .equalHMWrap {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  section#welcome .equalHM {
    width: 50%; }
  section#welcome .b-gray {
    background-color: #d0c8b9; }
  section#welcome .banya-text {
    -ms-flex-align: end;
    align-items: flex-end;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  section#welcome h1 {
    padding-top: 85px;
    font-family: "Cormorant", serif;
    font-size: 70px;
    line-height: 60px;
    font-weight: 700;
    color: #555555;
    margin: 0;
    text-transform: uppercase;
    text-align: right;
    padding-right: 25px; }
  section#welcome h2 {
    font-family: "Cormorant", serif;
    font-size: 36px;
    line-height: 50px;
    font-weight: 700;
    color: #ca383c;
    margin: 0;
    font-style: italic;
    text-align: right;
    padding-right: 25px;
    padding-bottom: 10px; }
  section#welcome h3 {
    font-family: "Cormorant", serif;
    font-size: 36px;
    line-height: 50px;
    font-weight: 700;
    color: #ffffff;
    width: 250px;
    padding-left: 25px;
    border-bottom: 1px solid #fff;
    margin: 0;
    padding-bottom: 10px; }
  section#welcome h4 {
    font-family: "Cormorant", serif;
    font-size: 30px;
    line-height: 50px;
    font-weight: 400;
    color: #555555;
    padding-left: 25px;
    font-style: italic;
    margin: 0;
    text-align: right;
    padding-bottom: 10px;
    padding-right: 25px;
    padding-top: 70px; }
  section#welcome p {
    font-family: "Cormorant", serif;
    color: #3e3c3a;
    text-align: left;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 0; }
    section#welcome p a {
      color: #960004;
      font-weight: 700; }
      section#welcome p a:hover {
        color: #bb0005; }
  section#welcome .welcome-txt {
    padding: 25px 0 130px 25px;
    background: transparent url(../img/welcome-stars.png) no-repeat bottom 25px left 25px; }

section.paralax {
  width: 100%;
  height: 700px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: left;
  justify-content: left;
  /* Create the parallax scrolling effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative; }
  section.paralax .paralax-h1-container {
    margin-left: 55px;
    width: 50%;
    height: 580px;
    overflow: hidden;
    border: 1px solid #fff;
    text-align: right;
    z-index: 1;
    position: relative;
    /*    a {
      &:hover {
        color: #c81414;
      }
    }*/ }
    section.paralax .paralax-h1-container h2 {
      position: absolute;
      bottom: 50px;
      right: 0;
      font-family: "Cormorant", serif;
      font-size: 70px;
      line-height: 70px;
      color: #ffffff;
      margin: 0;
      font-weight: 700;
      text-align: right;
      text-transform: uppercase;
      padding-right: 70px; }
      section.paralax .paralax-h1-container h2 span {
        color: #bb0000; }
  section.paralax .paralax-h3-container {
    position: absolute;
    left: 50%;
    -ms-flex-item-align: end;
    align-self: flex-end;
    width: 50%;
    background-color: #fff;
    overflow: hidden;
    text-align: center;
    z-index: 5; }
    section.paralax .paralax-h3-container h3 {
      display: block;
      font-family: "Cormorant", serif;
      font-size: 30px;
      line-height: 34px;
      color: #a89677;
      margin: 0;
      font-weight: 700;
      text-align: left;
      padding: 45px 35px; }
      section.paralax .paralax-h3-container h3 span {
        color: #bb0005; }

.destination {
  background: #001620 url(../img/index.jpg) no-repeat center center; }

section.paralax2 {
  position: relative;
  width: 100%;
  height: 700px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* Create the parallax scrolling effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; }
  section.paralax2 .ornam-img {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent url("../img/ornam-index-pic.png") no-repeat center left; }
  section.paralax2 .paralax2-h1-container {
    padding: 30px;
    overflow: hidden;
    text-align: center; }
    section.paralax2 .paralax2-h1-container h2 {
      font-family: "Cormorant", serif;
      font-size: 30px;
      line-height: 32px;
      color: #ffffff;
      margin: 0;
      font-weight: 400;
      text-align: center;
      font-style: italic; }

.guest {
  background: transparent url(../img/index-guest.jpg) no-repeat center center; }

.slick-slider {
  margin-bottom: 20px; }

div.slick3-arrows-container {
  text-align: center;
  padding-top: 0;
  padding-bottom: 15px; }
  div.slick3-arrows-container a.slick3-prev {
    height: 50px;
    width: 50px;
    display: inline-block;
    background: transparent url(../img/arrow_left.png) no-repeat center center;
    cursor: pointer;
    margin: 0 15px;
    transition: background-image 0.3s ease-in-out; }
    div.slick3-arrows-container a.slick3-prev:hover {
      background: transparent url(../img/arrow_left_over.png) no-repeat center center; }
  div.slick3-arrows-container a.slick3-next {
    height: 50px;
    width: 50px;
    display: inline-block;
    background: transparent url(../img/arrow_right.png) no-repeat center center;
    cursor: pointer;
    margin: 0 15px;
    transition: background-image 0.3s ease-in-out; }
    div.slick3-arrows-container a.slick3-next:hover {
      background: transparent url(../img/arrow_right_over.png) no-repeat center center; }

/*  xs */
@media (max-width: 767px) {
  section#welcome .eqWrap {
    display: block; }
  section#welcome .equalHM {
    width: 100%; }
  section#welcome h1 {
    text-align: left;
    font-size: 50px;
    padding-left: 25px;
    padding-top: 0; }
  section#welcome h2 {
    text-align: left;
    font-size: 28px;
    padding-left: 25px; }
  section#welcome h3 {
    font-size: 28px;
    padding-left: 25px;
    padding-bottom: 0; }
  section#welcome h4 {
    padding-top: 30px;
    padding-bottom: 0;
    font-size: 28px;
    text-align: left; }
  section#welcome p {
    text-align: left; }
  section#welcome .welcome-txt {
    padding-left: 0;
    background-position: bottom 40px left 0; }
  section.paralax {
    height: 580px; }
    section.paralax .paralax-h1-container {
      margin-left: 20px;
      width: calc(100% - 40px);
      height: 480px;
      overflow: hidden;
      border: 1px solid #fff;
      text-align: right;
      z-index: 1;
      position: relative; }
      section.paralax .paralax-h1-container h2 {
        position: absolute;
        bottom: 150px;
        right: 0;
        font-family: "Cormorant", serif;
        color: #ffffff;
        margin: 0;
        font-weight: 700;
        text-align: right;
        text-transform: uppercase;
        padding-right: 70px;
        font-size: 50px;
        line-height: 50px; }
    section.paralax .paralax-h3-container {
      position: absolute;
      left: auto;
      right: 0;
      -ms-flex-item-align: end;
      align-self: flex-end;
      width: 70%;
      background-color: #fff;
      overflow: hidden;
      text-align: center;
      z-index: 5; }
      section.paralax .paralax-h3-container h3 {
        font-size: 24px;
        padding: 35px; }
  section.paralax2 {
    height: 300px; }
    section.paralax2 .paralax2-h1-container h2 {
      font-size: 22px;
      line-height: 28px; } }

/*  sm */
@media (min-width: 767px) and (max-width: 991px) {
  section#welcome h1 {
    font-size: 38px;
    line-height: 46px;
    padding-top: 0; }
  section#welcome h2 h2 {
    text-align: center;
    font-size: 24px;
    line-height: 30px; }
  section.paralax .paralax-h1-container h2 {
    font-size: 50px;
    line-height: 50px; }
  section.paralax .paralax-h3-container h3 {
    font-size: 24px;
    padding: 35px; } }

/*  md */
@media (min-width: 992px) and (max-width: 1199px) {
  section#welcome h1 {
    padding-top: 0; } }

/* Kareta 1 Index */
#kareta1 {
  -webkit-backface-visibility: hidden;
  background: #fff;
  padding-bottom: 55px;
  padding-top: 55px; }
  #kareta1 .kareta1-item-container {
    overflow: hidden;
    position: relative; }
    #kareta1 .kareta1-item-container .img-container-left {
      position: relative;
      z-index: 3; }
    #kareta1 .kareta1-item-container .text-box-container {
      -webkit-backface-visibility: hidden;
      /* Chrome, Safari, Opera */
      backface-visibility: hidden;
      height: 100%;
      position: absolute;
      transition: background 0.3s ease;
      width: 50%;
      bottom: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0; }
      #kareta1 .kareta1-item-container .text-box-container h1 {
        font-family: "Cormorant", serif;
        font-size: 48px;
        line-height: 56px;
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;
        margin: 0 0 33px 0; }
      #kareta1 .kareta1-item-container .text-box-container p {
        color: #333333;
        text-align: left;
        font-family: "Cormorant", serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 400;
        margin: 0 0 0 80px;
        border-top: 1px solid #d5d3d1;
        padding-top: 12px; }
      #kareta1 .kareta1-item-container .text-box-container div.kareta1-date {
        font-family: "Cormorant", serif;
        font-size: 26px;
        color: #af9968;
        text-align: right;
        z-index: 1;
        white-space: nowrap;
        -webkit-backface-visibility: hidden;
        font-weight: 700;
        padding-top: 0;
        padding-bottom: 25px; }
        #kareta1 .kareta1-item-container .text-box-container div.kareta1-date span {
          font-family: "Cormorant", serif;
          display: inline-block;
          font-weight: 700;
          font-size: 70px;
          line-height: 70px;
          color: #a89677; }
      #kareta1 .kareta1-item-container .text-box-container div.kareta1-date-xs {
        text-align: right;
        font-family: "Cormorant", serif;
        font-size: 26px;
        font-weight: 700;
        line-height: 25px;
        color: #af9968;
        padding-top: 10px;
        padding-bottom: 5px; }
      #kareta1 .kareta1-item-container .text-box-container div.kareta1-price {
        font-family: "Cormorant", serif;
        font-size: 46px;
        color: #bb0005;
        line-height: 46px;
        font-weight: 700;
        text-align: left;
        padding-top: 30px;
        margin: 0 0 0 80px; }
        #kareta1 .kareta1-item-container .text-box-container div.kareta1-price span {
          font-size: 18px;
          font-weight: 400; }
      #kareta1 .kareta1-item-container .text-box-container a {
        color: #555555; }
      #kareta1 .kareta1-item-container .text-box-container a:hover {
        color: #990004; }
      #kareta1 .kareta1-item-container .text-box-container h2 {
        display: inline-block;
        font-family: "Cormorant", serif;
        font-size: 22px;
        line-height: 30px;
        font-weight: 700;
        text-align: left;
        margin: 0 0 80px 0;
        position: relative;
        padding-left: 85px;
        padding-right: 65px;
        border-bottom: 1px solid #d5d3d1;
        padding-bottom: 10px; }
        #kareta1 .kareta1-item-container .text-box-container h2 a {
          color: #817f7c; }
        #kareta1 .kareta1-item-container .text-box-container h2 a:hover {
          color: #bb0005; }
      #kareta1 .kareta1-item-container .text-box-container h2.hr {
        width: 250px;
        border-bottom: 1px solid #d5d3d1;
        padding-bottom: 10px; }
        #kareta1 .kareta1-item-container .text-box-container h2.hr a {
          color: #817f7c; }
        #kareta1 .kareta1-item-container .text-box-container h2.hr a:hover {
          color: #bb0005; }
  #kareta1 .kareta1-right {
    background-color: #ffffff; }
    #kareta1 .kareta1-right div.kareta1-date {
      right: 20px; }
    #kareta1 .kareta1-right .img-container-left {
      background-color: #fff;
      float: left;
      width: 50%;
      overflow: hidden; }
      #kareta1 .kareta1-right .img-container-left img {
        -webkit-backface-visibility: hidden;
        /* Chrome, Safari, Opera */
        backface-visibility: hidden;
        width: 100%; }
      #kareta1 .kareta1-right .img-container-left .ornam-img {
        position: absolute;
        z-index: 3;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: transparent url("../img/ornam-kareta1-img-r.png") no-repeat center right; }
    #kareta1 .kareta1-right .text-box-container {
      left: auto;
      right: 0; }
    #kareta1 .kareta1-right div.kareta1-title {
      padding: 0 115px 0 95px; }
    #kareta1 .kareta1-right div.kareta1-text {
      padding: 0 115px 0 95px; }
  #kareta1 .kareta1-left {
    background-color: #ffffff; }
    #kareta1 .kareta1-left div.kareta1-date {
      left: 20px; }
    #kareta1 .kareta1-left .img-container-left {
      background-color: #fff;
      float: right;
      width: 50%;
      overflow: hidden; }
      #kareta1 .kareta1-left .img-container-left img {
        -webkit-backface-visibility: hidden;
        /* Chrome, Safari, Opera */
        backface-visibility: hidden;
        width: 100%; }
    #kareta1 .kareta1-left .ornam-img {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent url("../img/ornam-kareta1-img-l.png") no-repeat center left; }
    #kareta1 .kareta1-left .text-box-container {
      left: 0;
      right: auto; }
    #kareta1 .kareta1-left div.kareta1-title {
      padding: 0 95px 0 115px; }
    #kareta1 .kareta1-left div.kareta1-text {
      padding: 0 95px 0 115px; }

/*  xs */
@media (max-width: 767px) {
  #kareta1 .kareta1-item-container {
    padding-top: 0; }
    #kareta1 .kareta1-item-container div.kareta1-date {
      font-size: 28px;
      text-align: center;
      padding-top: 20px; }
      #kareta1 .kareta1-item-container div.kareta1-date span {
        font-weight: 300; }
    #kareta1 .kareta1-item-container .text-box-container {
      -webkit-backface-visibility: hidden;
      /* Chrome, Safari, Opera */
      backface-visibility: hidden;
      height: auto;
      position: inherit;
      width: auto;
      bottom: auto;
      display: block;
      padding: 20px 0 30px 0; }
      #kareta1 .kareta1-item-container .text-box-container h1 {
        font-size: 34px;
        line-height: 36px;
        text-align: left;
        margin: 0 0 20px 0; }
      #kareta1 .kareta1-item-container .text-box-container p {
        text-align: left;
        font-size: 18px;
        line-height: 24px;
        margin: 0; }
      #kareta1 .kareta1-item-container .text-box-container h2 {
        font-size: 18px;
        line-height: 26px;
        text-align: left;
        margin: 0 0 10px 0;
        padding-bottom: 0;
        border-bottom: 0;
        padding-left: 0; }
      #kareta1 .kareta1-item-container .text-box-container div.kareta1-date-xs {
        padding: 10px 0 10px 0; }
      #kareta1 .kareta1-item-container .text-box-container div.kareta1-price {
        font-size: 34px;
        line-height: 34px;
        text-align: left;
        padding-top: 0;
        margin: 0; }
        #kareta1 .kareta1-item-container .text-box-container div.kareta1-price span {
          font-size: 24px;
          font-weight: 300; }
  #kareta1 .kareta1-right .img-container-left {
    float: left;
    width: auto;
    display: block;
    margin-bottom: 20px; }
  #kareta1 .kareta1-right .text-box-container {
    left: auto;
    right: auto; }
  #kareta1 .kareta1-right div.kareta1-title {
    padding: 0 20px; }
  #kareta1 .kareta1-right div.kareta1-text {
    padding: 0 20px 0 20px; }
  #kareta1 .kareta1-right .bottom-ornam .right {
    right: 0;
    left: auto;
    width: 22%;
    background-color: #f1eded; }
  #kareta1 .kareta1-left .img-container-left {
    float: left;
    width: auto;
    display: block;
    margin-bottom: 20px; }
  #kareta1 .kareta1-left .text-box-container {
    left: auto;
    right: auto; }
  #kareta1 .kareta1-left div.kareta1-title {
    padding: 0 20px; }
  #kareta1 .kareta1-left div.kareta1-text {
    padding: 0 20px 0 20px; } }

/*  sm */
@media (min-width: 767px) and (max-width: 991px) {
  #kareta1 .kareta1-item-container .text-box-container h1 {
    font-size: 40px;
    line-height: 50px; }
  #kareta1 .kareta1-item-container .text-box-container h2 {
    margin: 0 0 20px 0; }
  #kareta1 .kareta1-item-container .text-box-container div.kareta1-title, #kareta1 .kareta1-item-container .text-box-container div.kareta1-text {
    padding: 0 30px 0 30px; }
  #kareta1 .kareta1-item-container .text-box-container p {
    margin: 0; }
  #kareta1 .kareta1-item-container .text-box-container div.kareta1-price {
    margin: 0;
    padding-top: 0; }
  #kareta1 .kareta1-right .top-ornam, #kareta1 .kareta1-right .bottom-ornam {
    height: 40px; } }

/*  md */
@media (min-width: 992px) and (max-width: 1199px) {
  #kareta1 .kareta1-item-container .text-box-container div.kareta1-title, #kareta1 .kareta1-item-container .text-box-container div.kareta1-text {
    padding: 0 40px 0 40px; }
  #kareta1 .kareta1-item-container .text-box-container p {
    margin: 0; }
  #kareta1 .kareta1-item-container .text-box-container div.kareta1-price {
    margin: 0;
    padding-top: 0; }
  #kareta1 .kareta1-right .top-ornam, #kareta1 .kareta1-right .bottom-ornam {
    height: 50px; } }

/* Kareta 3 Index */
section#kareta3 {
  clear: both;
  padding-top: 75px; }
  section#kareta3 .kareta3-item-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  section#kareta3 .kareta3-item {
    -webkit-backface-visibility: hidden;
    /* Chrome, Safari, Opera */
    backface-visibility: hidden;
    width: 50%;
    margin-bottom: 70px;
    padding: 0 35px; }
    section#kareta3 .kareta3-item .text-box-container {
      position: relative;
      text-align: left;
      padding: 40px 100px 35px 30px;
      background-color: #d0c8b9; }
      section#kareta3 .kareta3-item .text-box-container h2 {
        font-family: "Cormorant", serif;
        font-size: 26px;
        line-height: 44px;
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;
        color: #fefefe; }
      section#kareta3 .kareta3-item .text-box-container p {
        color: #362824;
        font-family: "Cormorant", serif;
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        padding-bottom: 20px;
        margin: 0; }
      section#kareta3 .kareta3-item .text-box-container .kareta3-number {
        position: absolute;
        top: calc(50% - 18px);
        right: 30px;
        font-family: "Cormorant", serif;
        font-size: 36px;
        line-height: 36px;
        font-weight: 400;
        margin: 0;
        color: #bb0000; }
      section#kareta3 .kareta3-item .text-box-container a {
        color: #fefefe; }
      section#kareta3 .kareta3-item .text-box-container a:hover {
        color: #333333; }
    section#kareta3 .kareta3-item .img-container {
      position: relative; }
      section#kareta3 .kareta3-item .img-container img {
        width: 100%; }
      section#kareta3 .kareta3-item .img-container .ornam-square-border {
        position: absolute;
        top: 45px;
        left: 45px;
        width: calc(100% - 90px);
        height: calc(100% - 90px);
        background-color: transparent;
        border: 1px solid #fff;
        padding-left: 40px;
        padding-top: 50px;
        transition: all 0.3s ease;
        padding-right: 75px; }
        section#kareta3 .kareta3-item .img-container .ornam-square-border h1 {
          font-family: "Cormorant", serif;
          font-size: 38px;
          line-height: 44px;
          font-weight: 700;
          margin: 0;
          text-transform: uppercase;
          color: #fefefe; }
        section#kareta3 .kareta3-item .img-container .ornam-square-border:hover {
          background-color: #fff;
          opacity: .8; }
          section#kareta3 .kareta3-item .img-container .ornam-square-border:hover h1 {
            color: #333333; }

section.kareta3-inner {
  background-color: #f6f6f5;
  padding: 45px 0 0 0 !important; }
  section.kareta3-inner .kareta3-item {
    border-color: #f6f6f5 !important;
    margin-bottom: 70px  !important; }

/*  xs */
@media (max-width: 767px) {
  section#kareta3 {
    padding-top: 30px; }
    section#kareta3 .kareta3-item {
      width: 100%;
      margin-bottom: 50px;
      padding: 0; }
      section#kareta3 .kareta3-item .text-box-container {
        padding: 30px 40px 25px 20px; }
        section#kareta3 .kareta3-item .text-box-container h1 {
          font-size: 22px;
          line-height: 28px; }
        section#kareta3 .kareta3-item .text-box-container .kareta3-number {
          font-size: 28px; } }

/*  sm */
@media (min-width: 767px) and (max-width: 991px) {
  section#kareta3 .kareta3-item {
    padding: 0 15px;
    width: 50%; } }

/*  md */
@media (min-width: 992px) and (max-width: 1199px) {
  section#kareta3 .kareta3-item {
    width: 50%; } }

/*  lg */
@media (min-width: 1200px) {
  section#kareta3 .kareta3-item {
    width: 50%; } }

/*  > 1600 */
@media (min-width: 1600px) {
  section#kareta3 .kareta3-item {
    -webkit-backface-visibility: hidden;
    /* Chrome, Safari, Opera */
    backface-visibility: hidden;
    width: 50%; } }

/* Kareta 2 Index */
section#kareta2 {
  padding: 45px 0;
  clear: both; }
  section#kareta2 img {
    width: 100%; }
  section#kareta2 .header-section {
    padding-bottom: 50px; }
    section#kareta2 .header-section .eqWrap {
      display: -ms-flexbox;
      display: flex; }
    section#kareta2 .header-section .equalHMWrap {
      -ms-flex-pack: justify;
      justify-content: space-between; }
    section#kareta2 .header-section .equalHMWrap {
      -ms-flex-pack: justify;
      justify-content: space-between; }
    section#kareta2 .header-section .equalHM {
      width: 50%; }
    section#kareta2 .header-section .b-gray {
      background-color: #d0c8b9; }
    section#kareta2 .header-section .more-text h2 {
      font-family: "Cormorant", serif;
      font-size: 50px;
      line-height: 56px;
      font-weight: 700;
      text-align: right;
      position: relative;
      color: #555555;
      padding: 110px 15px 20px 15px;
      text-transform: uppercase;
      margin: 0; }
    section#kareta2 .header-section .stay-text {
      -ms-flex-align: end;
      align-items: flex-end;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: start;
      justify-content: flex-start; }
      section#kareta2 .header-section .stay-text h2 {
        font-family: "Cormorant", serif;
        font-size: 36px;
        line-height: 36px;
        font-weight: 700;
        text-align: left;
        position: relative;
        color: #ffffff;
        font-style: italic;
        padding: 20px 15px 30px 15px;
        margin: 0; }
  section#kareta2 .kareta2-item-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  section#kareta2 .img-container {
    background-color: #fff;
    position: relative; }
    section#kareta2 .img-container .ornam-square-border {
      position: absolute;
      top: 17px;
      left: 17px;
      width: calc(100% - 34px);
      height: calc(100% - 34px);
      background-color: transparent;
      border: 1px solid #fff; }
  section#kareta2 .kareta2-item {
    -webkit-backface-visibility: hidden;
    /* Chrome, Safari, Opera */
    backface-visibility: hidden;
    width: 33.333%;
    margin-bottom: 55px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column; }
    section#kareta2 .kareta2-item .text-box-container {
      padding-bottom: 20px;
      padding-left: 50px;
      padding-right: 50px;
      text-align: left;
      -ms-flex: 1;
      flex: 1; }
      section#kareta2 .kareta2-item .text-box-container h1 {
        font-family: "Cormorant", serif;
        font-size: 30px;
        line-height: 36px;
        font-weight: 700;
        margin: 0;
        padding-top: 17px;
        padding-bottom: 20px; }
      section#kareta2 .kareta2-item .text-box-container h2 {
        display: inline-block;
        font-family: "Cormorant", serif;
        font-size: 20px;
        line-height: 30px;
        font-weight: 700;
        text-align: left;
        margin: 0 0 5px 0;
        position: relative;
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 65px;
        border-bottom: 1px solid #d5d3d1;
        padding-bottom: 3px; }
        section#kareta2 .kareta2-item .text-box-container h2 a {
          color: #817f7c; }
        section#kareta2 .kareta2-item .text-box-container h2 a:hover {
          color: #111111; }
      section#kareta2 .kareta2-item .text-box-container p {
        color: #333333;
        font-family: "Cormorant", serif;
        font-size: 20px;
        line-height: 26px;
        border-top: 1px solid #d5d3d1;
        padding-top: 17px;
        font-weight: 400;
        padding-bottom: 0;
        margin: 0;
        padding-left: 40px; }
      section#kareta2 .kareta2-item .text-box-container div.kareta2-price {
        display: inline-block;
        font-family: "Cormorant", serif;
        font-size: 36px;
        color: #bb0005;
        line-height: 36px;
        font-weight: 700;
        text-align: left;
        padding-top: 15px;
        margin: 0 0 0 40px; }
        section#kareta2 .kareta2-item .text-box-container div.kareta2-price span {
          font-size: 18px;
          font-weight: 400; }
      section#kareta2 .kareta2-item .text-box-container div.kareta2-date {
        float: right;
        display: inline-block;
        font-family: "Cormorant", serif;
        font-size: 22px;
        color: #af9968;
        text-align: right;
        z-index: 1;
        white-space: nowrap;
        -webkit-backface-visibility: hidden;
        font-weight: 700;
        padding-top: 0; }
        section#kareta2 .kareta2-item .text-box-container div.kareta2-date span {
          font-family: "Cormorant", serif;
          display: inline-block;
          font-weight: 700;
          font-size: 42px;
          color: #af9968; }
      section#kareta2 .kareta2-item .text-box-container div.divider {
        display: inline-block;
        width: 50px;
        height: 1px;
        margin-top: 10px;
        margin-bottom: 0; }
      section#kareta2 .kareta2-item .text-box-container a {
        color: #555555; }
      section#kareta2 .kareta2-item .text-box-container a:hover {
        color: #980004; }
      section#kareta2 .kareta2-item .text-box-container .see-more-btn {
        background-color: #93e3cb;
        border: 0;
        color: #fff;
        margin-top: 25px; }
        section#kareta2 .kareta2-item .text-box-container .see-more-btn:hover {
          border: 0;
          background-color: #55d2ac;
          color: #fff; }

section#kareta2.rekareta3 {
  padding-bottom: 40px;
  position: relative; }
  section#kareta2.rekareta3 .slick-track {
    display: -ms-flexbox;
    display: flex; }
    section#kareta2.rekareta3 .slick-track .slick-slide {
      height: auto; }

.kareta2-gallery-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  width: 300px;
  padding: 20px 70px; }
  .kareta2-gallery-title a {
    font-family: "Cormorant", serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    font-style: italic;
    margin: 0;
    padding: 0; }

/* KARETA 2 FILTER BY YEARS */
.filter-years-container {
  background-color: transparent;
  font-family: "Cormorant", serif;
  font-size: 40px;
  line-height: 48px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  padding-top: 45px;
  padding-bottom: 0; }
  .filter-years-container a {
    color: #999999;
    padding: 0 18px;
    background-image: none;
    background-position: bottom left -20px;
    transition: all 0.3s ease-in-out; }
    .filter-years-container a:hover, .filter-years-container a.active {
      color: #bb0005; }

/*  xs */
@media (max-width: 767px) {
  section#kareta2 {
    padding: 15px 0 45px 0; }
    section#kareta2 .header-section .more-text h2 {
      font-size: 42px;
      line-height: 44px;
      text-align: left;
      padding-left: 40px;
      padding-top: 40px; }
    section#kareta2 .header-section .stay-text h2 {
      font-size: 28px;
      padding: 10px 40px 15px 40px; }
    section#kareta2 .header-section .eqWrap {
      display: block; }
    section#kareta2 .header-section .equalHM {
      width: 100%; }
    section#kareta2 .kareta2-item {
      width: 100%; }
      section#kareta2 .kareta2-item .text-box-container {
        padding-left: 25px;
        padding-right: 25px; }
        section#kareta2 .kareta2-item .text-box-container p {
          border-top: 0;
          padding-top: 0; }
  .filter-years-container {
    font-size: 30px;
    line-height: 34px;
    padding-bottom: 20px;
    padding-top: 20px; } }

/*  sm */
/*  md */
/*  lg */
/*  > 1600 */
/* Kareta Products */
/* kareta 1 products items (siven) */
#Container {
  padding-right: 35px;
  padding-left: 30px; }

.products-container {
  overflow: hidden; }

.products-wrapper {
  background-color: #f6f6f5; }

.kareta-products-item {
  width: 49.6%;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  transition: background 0.3s ease;
  -webkit-transition: background 300ms ease;
  -moz-transition: background 300ms ease;
  -o-transition: background 300ms ease;
  z-index: 1;
  padding-bottom: 30px;
  margin-bottom: 50px;
  padding-left: 25px;
  padding-right: 25px; }
  .kareta-products-item h1 {
    font-family: "Cormorant", serif;
    font-size: 28px;
    line-height: 34px;
    font-weight: 400;
    margin: 0; }
  .kareta-products-item p {
    color: #333333;
    font-family: "Cormorant", serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 300;
    margin: 0;
    margin-top: 20px; }
  .kareta-products-item div.kareta1-date {
    font-family: "Cormorant", serif;
    font-size: 26px;
    color: #af9968;
    font-weight: 400;
    text-align: center;
    padding-top: 20px; }
  .kareta-products-item div.kareta1-price {
    font-family: "Cormorant", serif;
    font-size: 46px;
    color: #bb0005;
    font-weight: 400;
    text-align: left;
    padding-top: 15px; }
    .kareta-products-item div.kareta1-price span {
      color: #78736b;
      margin-right: 30px;
      text-decoration: line-through; }
  .kareta-products-item a {
    color: #555555; }
  .kareta-products-item a:hover {
    color: #990004; }
  .kareta-products-item .text-box-container {
    overflow: hidden;
    padding-left: 60px;
    padding-right: 30px; }
    .kareta-products-item .text-box-container div.line-1-big {
      position: absolute;
      height: 64%;
      width: 3px;
      bottom: 0;
      left: 50px;
      border-left: 1px solid #d2cabf;
      z-index: 500; }
    .kareta-products-item .text-box-container div.line-1-small {
      position: absolute;
      height: 29%;
      width: 3px;
      bottom: 0;
      left: 53px;
      border-left: 1px solid #d2cabf;
      z-index: 500;
      margin-bottom: 30px; }
    .kareta-products-item .text-box-container h1 {
      margin-top: 27px;
      margin-bottom: 0; }
  .kareta-products-item ul {
    padding-top: 20px;
    margin: 0;
    text-align: center; }
    .kareta-products-item ul li {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-left: 17px; }
      .kareta-products-item ul li:first-of-type {
        margin-left: 0; }

.products-wrapper aside, #pageProduct aside {
  padding-left: 50px; }
  .products-wrapper aside h3, #pageProduct aside h3 {
    font-family: "Cormorant", serif;
    font-size: 16px;
    font-weight: bold;
    color: #999999;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 7px; }
  .products-wrapper aside ul, #pageProduct aside ul {
    margin-bottom: 60px; }
    .products-wrapper aside ul li, #pageProduct aside ul li {
      padding: 3px 40px 13px 0; }
      .products-wrapper aside ul li a, #pageProduct aside ul li a {
        display: block;
        font-family: "Cormorant", serif;
        font-size: 24px;
        line-height: 36px;
        font-weight: 400;
        text-transform: uppercase;
        color: #333333;
        width: 100%;
        padding: 0 15px; }
        .products-wrapper aside ul li a:hover, #pageProduct aside ul li a:hover {
          color: #826d4e; }
        .products-wrapper aside ul li a.active, #pageProduct aside ul li a.active {
          border-bottom: 1px solid #d2cabf;
          padding-bottom: 3px; }
      .products-wrapper aside ul li ul, #pageProduct aside ul li ul {
        margin: 0; }
        .products-wrapper aside ul li ul li, #pageProduct aside ul li ul li {
          border: 0;
          padding: 7px 15px 7px 15px;
          min-height: inherit; }
          .products-wrapper aside ul li ul li:first-of-type, #pageProduct aside ul li ul li:first-of-type {
            margin-top: 5px; }
          .products-wrapper aside ul li ul li a, #pageProduct aside ul li ul li a {
            font-family: "Cormorant", serif;
            font-size: 18px;
            line-height: 20px;
            font-weight: 400;
            padding: 1px 0;
            vertical-align: middle;
            color: #333333;
            padding-left: 40px;
            background: transparent url("../img/checkbox.png") no-repeat left center; }
            .products-wrapper aside ul li ul li a:hover, .products-wrapper aside ul li ul li a.active, #pageProduct aside ul li ul li a:hover, #pageProduct aside ul li ul li a.active {
              color: #333333;
              background: transparent url("../img/checkbox-checked.png") no-repeat left center; }

.products-wrapper .checkbox, #pageProduct .checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  margin: 0; }

.products-wrapper .checkbox input[type="checkbox"], #pageProduct .checkbox input[type="checkbox"] {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 0;
  opacity: 0;
  z-index: 1; }

.products-wrapper .checkbox label, #pageProduct .checkbox label {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  padding-left: 32px;
  min-height: inherit;
  font-family: "Cormorant", serif;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal; }

.products-wrapper .checkbox label:before,
.products-wrapper .checkbox label:after, #pageProduct .checkbox label:before,
#pageProduct .checkbox label:after {
  content: '';
  display: block;
  position: absolute; }

.products-wrapper .checkbox label:before, #pageProduct .checkbox label:before {
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background: #f6f6f5;
  border: 1px solid #ddd; }

.products-wrapper .checkbox label:after, #pageProduct .checkbox label:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background: #f6f6f5 url("../img/checkbox-checked.png") no-repeat center center;
  opacity: 0;
  pointer-events: none; }

.products-wrapper .checkbox input:checked + label, #pageProduct .checkbox input:checked + label {
  color: #333333; }

.products-wrapper .checkbox input:checked ~ label:after, #pageProduct .checkbox input:checked ~ label:after {
  opacity: 1; }

.products-wrapper .checkbox input:focus ~ label:before, #pageProduct .checkbox input:focus ~ label:before {
  background: #f6f6f5; }

.products-wrapper .choose-container, #pageProduct .choose-container {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding-top: 15px; }
  .products-wrapper .choose-container .checkbox, #pageProduct .choose-container .checkbox {
    padding-bottom: 13px; }
    .products-wrapper .choose-container .checkbox label, #pageProduct .choose-container .checkbox label {
      text-transform: uppercase;
      font-weight: 300; }

.product-grid .mix {
  display: none; }

/*  xs */
@media (max-width: 767px) {
  aside#FiltersContainer {
    display: none; }
  aside#FiltersContainerProduct {
    display: none; }
  #Container {
    padding-right: 30px;
    padding-left: 30px; }
  .products-wrapper aside, #pageProduct aside {
    padding-left: 30px; }
    .products-wrapper aside ul, #pageProduct aside ul {
      margin-bottom: 60px; }
      .products-wrapper aside ul li, #pageProduct aside ul li {
        padding: 3px 30px 13px 0; }
  .kareta-products-item {
    width: 100%;
    clear: both;
    margin-bottom: 30px;
    padding: 0; }
    .kareta-products-item h1 {
      font-size: 22px;
      line-height: 28px; }
    .kareta-products-item p {
      padding-bottom: 10px;
      margin: 0; }
    .kareta-products-item .text-box-container {
      overflow: hidden;
      padding-left: 45px;
      padding-right: 25px; }
      .kareta-products-item .text-box-container div.line-1-big {
        position: absolute;
        height: 64%;
        width: 3px;
        bottom: 0;
        left: 20px;
        border-left: 1px solid #d2cabf;
        z-index: 500;
        margin-bottom: 70px; }
      .kareta-products-item .text-box-container div.line-1-small {
        position: absolute;
        height: 42%;
        width: 3px;
        bottom: 0;
        left: 23px;
        border-left: 1px solid #d2cabf;
        z-index: 500;
        margin-bottom: 100px; }
      .kareta-products-item .text-box-container h1 {
        margin-top: 20px;
        margin-bottom: 17px; }
      .kareta-products-item .text-box-container div.kareta1-price {
        padding-top: 0; } }

/*  sm */
@media (min-width: 767px) and (max-width: 991px) {
  aside#FiltersContainer {
    display: none; }
  aside#FiltersContainerProduct {
    display: none; }
  #Container {
    padding-right: 30px;
    padding-left: 30px; }
  .products-wrapper aside, #pageProduct aside {
    padding-left: 30px; }
    .products-wrapper aside ul, #pageProduct aside ul {
      margin-bottom: 60px; }
      .products-wrapper aside ul li, #pageProduct aside ul li {
        padding: 3px 30px 13px 0; }
  .kareta-products-item {
    margin-bottom: 30px; }
    .kareta-products-item h1 {
      font-size: 24px;
      line-height: 30px; }
    .kareta-products-item .text-box-container {
      padding-right: 0; }
      .kareta-products-item .text-box-container div.kareta1-price span {
        margin-right: 10px; } }

/*  md */
@media (min-width: 992px) and (max-width: 1199px) {
  #Container {
    padding-left: 0; }
  .kareta-products-item {
    margin-bottom: 30px; }
    .kareta-products-item h1 {
      font-size: 24px;
      line-height: 30px; }
    .kareta-products-item .text-box-container {
      padding-right: 0; }
      .kareta-products-item .text-box-container div.kareta1-price span {
        margin-right: 15px; } }

/*  lg > 1200 */
@media (min-width: 1200px) {
  #Container {
    padding-left: 0; } }

/*  1200 - 1400 */
@media (min-width: 1200px) and (max-width: 1399px) {
  .kareta-products-item .text-box-container {
    padding-right: 0; } }

/*  1400 - 1600 */
/*  > 1600 */
/* Footer */
/* FOOTER */
footer {
  position: relative;
  color: #fff;
  width: 100%; }
  footer a {
    color: #ffffff;
    text-decoration: none;
    transition: 0.3s; }
    footer a:hover {
      color: #d2c6b4;
      text-decoration: none; }
    footer a:focus, footer a:visited, footer a:active {
      color: #d2c6b4;
      outline: none;
      text-decoration: none; }
  footer div#footerNav {
    font-family: "Cormorant", serif;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
    padding-top: 30px;
    padding-bottom: 10px; }
    footer div#footerNav ul {
      padding: 0;
      margin: 0; }
      footer div#footerNav ul li {
        display: inline;
        text-align: center;
        padding: 0 13px;
        padding-bottom: 5px;
        background-image: none;
        background-position: bottom center;
        transition: all 0.3s ease-in-out; }
        footer div#footerNav ul li a {
          white-space: nowrap;
          color: #282828;
          text-decoration: none; }
          footer div#footerNav ul li a:hover, footer div#footerNav ul li a.active {
            color: #fff; }
        footer div#footerNav ul li.active {
          background: transparent url(../img/orna-footer-nav.png) no-repeat bottom center; }
  footer div#upperFooter {
    padding-top: 30px;
    text-align: center; }
    footer div#upperFooter h2 {
      font-family: "Cormorant", serif;
      font-size: 32px;
      line-height: 32px;
      font-weight: 700;
      color: #282828;
      padding-bottom: 14px;
      padding-top: 20px;
      background: transparent url(../img/footer-stars.png) no-repeat center top;
      margin: 0; }
  footer div#bottomFooter {
    position: relative;
    background-color: #a19785;
    text-align: center;
    /*    .follow-us-container {
          width: 100%;
          position: relative;
        }*/ }
    footer div#bottomFooter address {
      text-align: center;
      padding-top: 25px;
      padding-bottom: 10px;
      font-family: "Cormorant", serif;
      font-size: 24px;
      line-height: 30px;
      margin: 0;
      color: #fff;
      font-weight: 700; }
      footer div#bottomFooter address p {
        margin: 0; }
        footer div#bottomFooter address p.tel {
          padding-top: 10px; }
          footer div#bottomFooter address p.tel a {
            color: #fff;
            font-size: 36px; }
            footer div#bottomFooter address p.tel a:hover {
              color: #282828; }
    footer div#bottomFooter div.followus {
      position: absolute;
      top: 0;
      right: 50px;
      padding-bottom: 25px;
      padding-top: 30px; }
      footer div#bottomFooter div.followus a {
        padding: 0 22px; }
        footer div#bottomFooter div.followus a img {
          transition: all 0.3s ease; }
        footer div#bottomFooter div.followus a:hover img {
          opacity: .65; }
    footer div#bottomFooter .orna-footer {
      background: transparent url(../img/orna-footer.png) no-repeat top right;
      height: 68px; }
  footer .hotels {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 40px; }
    footer .hotels .hotel1, footer .hotels .hotel2, footer .hotels .hotel3 {
      display: inline-block;
      width: 140px;
      height: 120px;
      border: 1px solid #7b7260;
      margin: 11px 11px; }
    footer .hotels .hotel1 {
      background: transparent url(../img/1-.png) no-repeat center center;
      transition: all 0.3s ease-in-out; }
      footer .hotels .hotel1:hover {
        background: transparent url(../img/1.png) no-repeat center center; }
    footer .hotels .hotel2 {
      background: transparent url(../img/2-.png) no-repeat center center;
      transition: all 0.3s ease-in-out; }
      footer .hotels .hotel2:hover {
        background: transparent url(../img/2.png) no-repeat center center; }
    footer .hotels .hotel3 {
      background: transparent url(../img/3-.png) no-repeat center center;
      transition: all 0.3s ease-in-out; }
      footer .hotels .hotel3:hover {
        background: transparent url(../img/3.png) no-repeat center center; }

/* shareThis */
div.footerShareThis {
  padding-top: 35px;
  padding-bottom: 25px;
  text-align: center; }

div#arrowUp {
  position: absolute;
  top: 22px;
  right: 50px;
  z-index: 98; }
  div#arrowUp a {
    height: 16px;
    width: 16px;
    display: block;
    background: transparent url(../img/arrow_up.png) no-repeat center center; }
    div#arrowUp a:hover {
      background: transparent url(../img/arrow_up_hover.png) no-repeat center center; }

div#likeButton a {
  margin: 0 15px; }

#likeButtonOpen {
  display: inline-block;
  width: 40px;
  height: 40px;
  transition: background-image 0.2s ease-in-out;
  background: transparent url(../img/fb-like.png) no-repeat center center; }
  #likeButtonOpen:hover {
    background: transparent url(../img/fb-like-hover.png) no-repeat center center; }

.shareButton {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: top;
  transition: background-image 0.2s ease-in-out;
  background: transparent url(../img/share.png) no-repeat center center; }
  .shareButton:hover {
    background: transparent url(../img/share-hover.png) no-repeat center center; }

.gobackbutton {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: top;
  background: transparent url(../img/goback.png) no-repeat center center; }
  .gobackbutton:hover {
    background: transparent url(../img/goback-hover.png) no-repeat center center; }

#likeButtonOpen img:hover, .shareButtonOpen img:hover {
  opacity: 0.6; }

#like_butt_hold {
  overflow: hidden;
  margin-top: 20px;
  height: 25px;
  display: none; }

/* fb overwrite da izravni */
.fb_iframe_widget {
  height: 20px !important;
  vertical-align: top !important; }
  .fb_iframe_widget span {
    vertical-align: top !important; }

/* footer copywrite */
.copywrite {
  background-color: #282828;
  font-family: "Cormorant", serif;
  font-size: 16px;
  color: #d2c6b4;
  padding-top: 18px;
  padding-bottom: 20px;
  font-weight: 400;
  text-align: center;
  position: relative; }
  .copywrite a {
    color: #e7e7e7 !important;
    font-weight: 400; }
    .copywrite a:hover {
      color: #ffffff !important;
      text-decoration: none; }

/*  xs */
@media (max-width: 767px) {
  div#likeButton a {
    margin: 0 18px; }
  footer div#bottomFooter div.followus {
    position: inherit;
    right: auto;
    top: auto;
    display: block;
    padding-bottom: 40px;
    padding-top: 0; } }

/* Current Page Text */
/* Current Page Text All pages */
.currentPageText {
  font-family: "Cormorant", serif;
  position: relative;
  background-color: transparent;
  text-align: center;
  padding-bottom: 0; }
  .currentPageText .eqWrap {
    display: -ms-flexbox;
    display: flex; }
  .currentPageText .equalHMWrap {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .currentPageText .equalHMWrap {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .currentPageText .equalHM {
    width: 50%; }
  .currentPageText .b-gray {
    background-color: #d0c8b9; }
  .currentPageText .theText {
    position: relative;
    text-align: left;
    padding-bottom: 90px;
    padding-left: 25px; }
    .currentPageText .theText:before {
      content: '';
      display: block;
      width: 300px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0; }
  .currentPageText p {
    font-size: 20px;
    line-height: 26px;
    color: #443930;
    font-weight: 400;
    margin: 0; }
    .currentPageText p:first-of-type {
      padding-top: 20px; }
    .currentPageText p a {
      color: #960004;
      font-weight: 700; }
      .currentPageText p a:hover {
        color: #bb0005; }
  .currentPageText h1 {
    padding-top: 85px;
    font-family: "Cormorant", serif;
    font-size: 50px;
    line-height: 60px;
    font-weight: 700;
    color: #555555;
    margin: 0;
    text-transform: uppercase;
    text-align: right;
    padding-right: 25px;
    padding-bottom: 15px;
    padding-left: 25px; }
  .currentPageText ul li {
    font-size: 20px;
    line-height: 26px;
    color: #443930;
    font-weight: 400;
    margin: 0; }
  .currentPageText .content-inner-text {
    padding-top: 110px;
    display: -ms-flexbox;
    display: flex; }
    .currentPageText .content-inner-text a {
      display: block;
      font-family: "Cormorant", serif;
      font-size: 30px;
      line-height: 36px;
      font-weight: 600;
      color: #ffffff;
      margin: 0;
      text-align: left;
      padding-right: 90px;
      padding-bottom: 12px;
      padding-left: 20px;
      border-bottom: 1px solid #fff; }
      .currentPageText .content-inner-text a:hover {
        color: #555555; }

.currentPageTextBottom {
  clear: both;
  padding-top: 30px; }

/*  xs */
@media (max-width: 767px) {
  .currentPageText h1 {
    padding-top: 60px;
    font-size: 30px;
    line-height: 38px;
    text-align: left; }
  .currentPageText .theText {
    position: relative;
    text-align: left;
    padding-bottom: 50px;
    padding-left: 0; }
    .currentPageText .theText:before {
      height: 0; }
  .currentPageText .eqWrap {
    display: block; }
  .currentPageText .equalHM {
    width: 100%; }
  .currentPageTextBottom {
    clear: both;
    padding-top: 0; } }

/*  sm */
@media (min-width: 767px) and (max-width: 991px) {
  .currentPageText h1 {
    padding-top: 60px;
    font-size: 30px;
    line-height: 38px;
    text-align: left; }
  .currentPageText .theText {
    position: relative;
    text-align: left;
    padding-bottom: 50px;
    padding-left: 0; }
    .currentPageText .theText:before {
      height: 0; }
  .currentPageText .eqWrap {
    display: block; }
  .currentPageText .equalHM {
    width: 100%; } }

/* Image Over Effects */
/* a img hover effect */
.img-container {
  position: relative;
  overflow: hidden; }

.img-container img {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  /* Safari and Chrome */
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  /* IE 9 */ }

/* all images Over effect */
.over-mask:hover {
  background-color: #fff; }

.over-mask img {
  -webkit-backface-visibility: hidden;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 0.5s ease; }

.over-mask:hover img {
  opacity: .7;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 0.5s ease; }

.over-mask-3 > a {
  background-color: #fff; }

.over-mask-3 img {
  -webkit-backface-visibility: hidden;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 0.5s ease; }

.over-mask-3:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 0.5s ease; }

/* all images Over effect DARK */
.over-mask-dark {
  background-color: #0e1d2e; }

.over-mask-dark img {
  -webkit-backface-visibility: hidden;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 0.5s ease; }

.over-mask-dark:hover img {
  opacity: .5;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 0.5s ease; }

/* Breadcrumbs */
/* Breadcrumbs kareta1/ kareta2*/
div#breadcrumbs-container {
  font-family: "Cormorant", serif;
  background-color: transparent;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 5px;
  font-weight: 300;
  font-size: 16px;
  color: #a99e8d;
  line-height: 20px; }
  div#breadcrumbs-container span {
    padding: 0 1px; }
  div#breadcrumbs-container a {
    color: #a99e8d;
    text-decoration: none; }
    div#breadcrumbs-container a:hover {
      color: #bb0000;
      text-decoration: none; }
    div#breadcrumbs-container a.active {
      color: #a99e8d;
      text-decoration: none; }

.nobanner {
  padding-top: 100px; }

.breadcrumbs-in-page {
  height: 60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 !important; }

/*  xs */
/*  sm */
/* Page Content */
/* Page Content & Page Product */
article#pageContent {
  background-color: #d0c8b9;
  text-align: left;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 80px; }
  article#pageContent h1 {
    padding-top: 85px;
    font-family: "Cormorant", serif;
    font-size: 56px;
    line-height: 60px;
    font-weight: 700;
    color: #555555;
    margin: 0;
    text-transform: uppercase; }
  article#pageContent p {
    font-family: "Cormorant", serif;
    font-size: 20px;
    line-height: 26px;
    color: #3e3c3a;
    text-align: left;
    font-weight: 400;
    margin-bottom: 0; }
    article#pageContent p:last-of-type {
      margin-bottom: 0; }
    article#pageContent p:first-of-type {
      padding-top: 30px; }
    article#pageContent p a {
      color: #960004;
      font-weight: 700; }
      article#pageContent p a:hover {
        color: #bb0005; }
  article#pageContent ul li {
    font-family: "Cormorant", serif;
    font-size: 20px;
    line-height: 26px;
    color: #3e3c3a;
    text-align: center;
    font-weight: 300;
    margin-bottom: 0; }
  article#pageContent div.date-price-container {
    padding-top: 40px;
    text-align: left; }
  article#pageContent div.page-content-date {
    font-family: "Cormorant", serif;
    font-size: 26px;
    line-height: 56px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    padding: 15px 0; }
    article#pageContent div.page-content-date span {
      font-weight: 700;
      font-size: 46px; }
    article#pageContent div.page-content-date span.vertical {
      width: 1px;
      height: 30px;
      background-color: #b5aea2;
      display: inline-block;
      margin: 0 12px;
      vertical-align: middle; }
  article#pageContent div.page-content-price {
    font-family: "Cormorant", serif;
    font-size: 46px;
    color: #bb0005;
    font-weight: 700;
    line-height: 38px;
    text-align: center;
    display: inline-block;
    padding: 15px 0;
    padding-right: 90px; }
    article#pageContent div.page-content-price span {
      font-size: 18px;
      font-weight: 700; }
    article#pageContent div.page-content-price i {
      font-size: 24px;
      font-weight: 400;
      font-style: italic;
      padding-right: 0; }
  article#pageContent div.date-price-divider {
    padding: 40px 25px 0 25px;
    font-family: "Cormorant", serif;
    font-size: 46px;
    color: #cccccc;
    font-weight: 300;
    text-align: center;
    display: inline-block; }
  article#pageContent .reserv-div-content {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fff;
    width: 380px;
    height: 120px;
    text-align: left; }
    article#pageContent .reserv-div-content div.contactus-btn-dark {
      position: inherit;
      left: auto;
      right: auto;
      bottom: auto;
      padding-left: 55px; }
      article#pageContent .reserv-div-content div.contactus-btn-dark a {
        width: 100%;
        display: block;
        height: 30px;
        padding-left: 55px;
        margin-top: 40px;
        background: transparent url("../img/booknow-up-inner.png") no-repeat bottom 5px left; }
        article#pageContent .reserv-div-content div.contactus-btn-dark a span {
          text-align: left;
          font-family: "Cormorant", serif;
          font-size: 24px;
          line-height: 30px;
          text-transform: uppercase;
          font-weight: 700;
          color: #555555; }
        article#pageContent .reserv-div-content div.contactus-btn-dark a:hover {
          background: transparent url("../img/booknow-down-inner.png") no-repeat bottom 5px left; }
          article#pageContent .reserv-div-content div.contactus-btn-dark a:hover span {
            color: #ff0007; }

.page-content-btn-container {
  position: absolute;
  bottom: -48px;
  right: 440px;
  padding-top: 30px; }

a.page-360-container {
  padding-top: 65px;
  display: inline-block;
  background: transparent url(../img/btn-360.png) no-repeat top 35px center;
  transition: all 0.3s ease;
  border: 1px solid #ada69a;
  padding-bottom: 70px; }
  a.page-360-container span.btxt {
    font-family: "Cormorant", serif;
    color: #555555;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    padding: 0 23px;
    text-transform: uppercase; }

a.page-360-container:hover {
  background: transparent url(../img/btn-360-over.png) no-repeat top 35px center; }
  a.page-360-container:hover span.btxt {
    color: #bb0005; }

a.book-now-page-content {
  margin-right: 15px;
  margin-left: 15px;
  padding-top: 110px;
  display: inline-block;
  background: transparent url(../img/btn-book.png) no-repeat top center;
  border: 0;
  transition: all 0.3s ease; }
  a.book-now-page-content span.txt {
    font-family: "Cormorant", serif;
    color: #aa0000;
    font-weight: 400;
    font-size: 17px;
    line-height: 34px;
    text-align: center;
    font-style: italic; }

a.book-now-page-content:hover {
  background: transparent url(../img/btn-book-over.png) no-repeat top center; }
  a.book-now-page-content:hover span.txt {
    color: #cc0000; }

/* Arrows - Previous, Next... Page-Product */
.arrow-nav {
  height: 100%; }
  .arrow-nav .arrow-left-container {
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-bottom: 17px;
    padding-right: 20px; }
    .arrow-nav .arrow-left-container a.left {
      display: inline-block;
      width: 80px;
      height: 80px;
      background: transparent url(../img/arrow2_left.png) no-repeat center center;
      transition: background-image 0.2s ease-in-out;
      border: 1px solid #ada69a;
      margin: 0 8px; }
      .arrow-nav .arrow-left-container a.left:hover {
        background: transparent url(../img/arrow2_left_over.png) no-repeat center center;
        border: 1px solid #d0c8b9; }
    .arrow-nav .arrow-left-container a.right {
      display: inline-block;
      width: 80px;
      height: 80px;
      margin: 0 8px;
      background: transparent url(../img/arrow2_right.png) no-repeat center center;
      transition: background-image 0.2s ease-in-out;
      border: 1px solid #ada69a; }
      .arrow-nav .arrow-left-container a.right:hover {
        background: transparent url(../img/arrow2_right_over.png) no-repeat center center;
        border: 1px solid #d0c8b9; }

div.page-content-gallery-container {
  padding-top: 0;
  text-align: center;
  border-bottom: 1px solid #c1b19c;
  position: relative;
  height: 32px; }
  div.page-content-gallery-container h2 {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    display: inline-block;
    background-color: #fff;
    padding: 0 16px;
    font-family: "Cormorant", serif;
    font-size: 24px;
    line-height: 32px;
    font-weight: 300;
    color: #998567;
    font-style: normal;
    margin: 0;
    padding-top: 10px; }
  div.page-content-gallery-container:before {
    border-bottom: 1px solid #c1b19c; }

#grid-container {
  margin-bottom: 0;
  margin-top: 40px;
  padding-bottom: 0; }

.cbp-popup-wrap {
  z-index: 999999; }

.cbp-caption-fadeIn .cbp-caption-activeWrap {
  background-color: rgba(255, 255, 255, 0.3);
  height: 100%;
  width: 100%; }

/* TABLES RESPONSIVE */
.table-responsive table caption h2 {
  font-family: "Cormorant", serif;
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  color: #413737;
  text-align: center; }

.table-responsive table caption h3 {
  font-family: "Cormorant", serif;
  font-size: 26px;
  line-height: 30px;
  font-weight: 300;
  color: #413737;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px; }

.table-responsive table thead {
  font-family: "Cormorant", serif;
  font-size: 18px;
  line-height: 20px;
  font-weight: 300;
  background-color: #c81414;
  color: #fff; }
  .table-responsive table thead tr th {
    padding: 10px 20px;
    text-align: center;
    border-bottom: 0; }
    .table-responsive table thead tr th:first-of-type {
      text-align: left; }

.table-responsive table tbody {
  font-family: "Cormorant", serif;
  font-size: 18px;
  line-height: 20px;
  font-weight: 300;
  color: #413737; }
  .table-responsive table tbody tr td {
    padding: 10px 20px;
    border-top: 0;
    border-bottom: 1px solid #ddd;
    text-align: center; }
    .table-responsive table tbody tr td:first-of-type {
      text-align: left; }

/*  xs */
@media (max-width: 767px) {
  article#pageContent {
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px; }
    article#pageContent h1 {
      font-size: 36px;
      line-height: 42px;
      margin: 0; }
    article#pageContent div.date-price-container {
      padding-top: 20px; }
  /* BOOK NOW page content */
  #grid-container {
    margin-top: 60px; } }

/*  lg > 1200 */
@media (min-width: 1200px) {
  article#pageContent {
    position: relative;
    padding-left: 100px;
    padding-right: 100px; }
  #relativeDiv {
    padding-left: 95px; }
  .txt {
    padding-right: 220px; } }

/*  lg < 1200 */
@media (max-width: 1200px) {
  .currentPageText .content-inner-text {
    padding-top: 60px; }
    .currentPageText .content-inner-text a {
      font-size: 24px;
      line-height: 30px;
      padding-right: 40px; }
  article#pageContent {
    position: relative;
    margin-bottom: 0; }
    article#pageContent h1 {
      padding-top: 40px; }
    article#pageContent .reserv-div-content {
      display: inline-block;
      position: inherit;
      bottom: auto;
      right: auto;
      background-color: #fff;
      width: 320px;
      height: 100%;
      margin-top: 30px;
      text-align: left; }
      article#pageContent .reserv-div-content div.contactus-btn-dark {
        position: inherit;
        left: auto;
        right: auto;
        bottom: auto;
        padding-left: 55px;
        padding-top: 20px;
        padding-bottom: 20px; }
        article#pageContent .reserv-div-content div.contactus-btn-dark a {
          margin-top: 0;
          width: 100%;
          display: block;
          height: 30px;
          padding-left: 55px;
          background: transparent url("../img/booknow-up-inner.png") no-repeat bottom 5px left; }
          article#pageContent .reserv-div-content div.contactus-btn-dark a span {
            text-align: left;
            font-family: "Cormorant", serif;
            font-size: 24px;
            line-height: 30px;
            text-transform: uppercase;
            font-weight: 700;
            color: #555555; }
          article#pageContent .reserv-div-content div.contactus-btn-dark a:hover {
            background: transparent url("../img/booknow-down-inner.png") no-repeat bottom 5px left; }
            article#pageContent .reserv-div-content div.contactus-btn-dark a:hover span {
              color: #ff0007; }
  .page-content-btn-container {
    display: inline-block;
    position: inherit;
    bottom: auto;
    right: auto;
    padding-top: 10px;
    padding-right: 30px; }
  a.page-360-container {
    padding-top: 65px;
    display: inline-block;
    background: transparent url(../img/btn-360.png) no-repeat top 35px center;
    transition: all 0.3s ease;
    border: 1px solid #ada69a;
    padding-bottom: 20px; }
    a.page-360-container span.btxt {
      font-family: "Cormorant", serif;
      color: #555555;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      padding: 0 23px;
      text-transform: uppercase; }
  a.page-360-container:hover {
    background: transparent url(../img/btn-360-over.png) no-repeat top 35px center; }
    a.page-360-container:hover span.btxt {
      color: #bb0005; } }

.cbp a:hover img {
  -webkit-transform: scale(1.1) !important;
  transform: scale(1.1) !important;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 0.5s ease; }

.cbp img {
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  transition: all 0.4s ease-in-out; }

.cbp-caption-fadeIn .cbp-caption:hover .cbp-caption-activeWrap {
  opacity: 0; }

/* Page Product */
article#pageProduct {
  padding-bottom: 50px;
  /* Arrows - Previous, Next... Page-Product */ }
  article#pageProduct p:last-of-type {
    margin-bottom: 30px; }
  article#pageProduct .other-info {
    padding: 7px 0;
    line-height: 26px;
    color: #222222; }
    article#pageProduct .other-info .other-info-label {
      font-family: "Cormorant", serif;
      font-size: 18px;
      font-style: italic;
      font-weight: 400; }
    article#pageProduct .other-info .other-info-txt {
      font-family: "Cormorant", serif;
      font-size: 20px;
      font-weight: 300;
      padding-left: 20px; }
  article#pageProduct .make-request-btn {
    display: inline-block;
    transition: color 0.2s ease;
    font-family: "Cormorant", serif;
    color: #333333;
    background-color: #fff;
    padding: 8px 80px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    text-transform: uppercase;
    border-top: 1px solid #d2cabf;
    border-bottom: 1px solid #d2cabf;
    margin-bottom: 20px; }
    article#pageProduct .make-request-btn:hover {
      color: #826d4e; }
  article#pageProduct .arrowNav {
    margin-top: 20px;
    overflow: hidden; }
    article#pageProduct .arrowNav a {
      font-family: "Cormorant", serif;
      font-weight: bold;
      font-style: italic;
      font-size: 18px;
      color: #c0984f;
      line-height: 55px;
      height: 55px;
      width: 55px;
      text-decoration: none; }
      article#pageProduct .arrowNav a:hover {
        color: #9b7b42; }
    article#pageProduct .arrowNav .arrow-left-container {
      margin: 0 20px;
      display: block;
      float: left;
      width: 60px;
      height: 33px;
      background: url(../img/slick-arrow-prev.png) no-repeat center center;
      transition: background 0.3s ease; }
      article#pageProduct .arrowNav .arrow-left-container:hover {
        opacity: 0.7; }
    article#pageProduct .arrowNav .arrow-right-container {
      margin: 0 20px;
      display: block;
      float: right;
      width: 60px;
      height: 33px;
      background: url(../img/slick-arrow-next.png) no-repeat center center;
      transition: background 0.3s ease; }
      article#pageProduct .arrowNav .arrow-right-container:hover {
        opacity: 0.7; }

/*  xs */
@media (max-width: 767px) {
  article#pageProduct div.page-content-price {
    font-size: 28px; }
  article#pageProduct .make-request-btn {
    padding: 8px 30px; } }

/*  md */
@media (min-width: 992px) and (max-width: 1199px) {
  article#pageProduct .make-request-btn {
    padding: 8px 30px; } }

/*  lg */
@media (min-width: 1200px) {
  article#pageProduct h1 {
    padding-right: 60px; }
  article#pageProduct .arrowNav {
    margin-top: 35px; } }

/*  1200 - 1400 */
@media (min-width: 1200px) and (max-width: 1399px) {
  article#pageProduct .arrowNav .arrow-left-container, article#pageProduct .arrowNav .arrow-right-container {
    margin: 0 15px; } }

/*  1400 - 1600 */
@media (min-width: 1400px) and (max-width: 1599px) {
  article#pageProduct .arrowNav {
    padding-right: 30px; } }

/*  > 1600 */
@media (min-width: 1600px) {
  article#pageProduct .arrowNav {
    padding-right: 60px; } }

/* Page Gallery */
/* overwrites kareta2 */
section#kareta2 .kareta-gallery .kareta2-item-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

section#kareta2 .kareta-gallery .kareta2-item {
  margin-bottom: 50px; }
  section#kareta2 .kareta-gallery .kareta2-item .text-box-container {
    padding: 0 20px 0 20px;
    background: none; }
    section#kareta2 .kareta-gallery .kareta2-item .text-box-container .kareta2-number {
      font-family: "Cormorant", serif;
      font-size: 28px;
      line-height: 28px;
      color: #6d5f46;
      font-style: italic; }
      section#kareta2 .kareta-gallery .kareta2-item .text-box-container .kareta2-number:before, section#kareta2 .kareta-gallery .kareta2-item .text-box-container .kareta2-number:after {
        content: '';
        display: inline-block;
        margin: 0 11px;
        width: 62px;
        height: 16px;
        background: transparent url("../img/gallery-ornam.png") no-repeat center center; }

/* Page Contact */
/* Page Contact */
div.feedback {
  padding-bottom: 0; }

div.feedback h2 {
  font-family: "Cormorant", serif;
  font-size: 38px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  padding-top: 85px;
  line-height: 40px;
  color: #555555;
  text-align: center; }

div.feedback-map {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  background-color: #fff;
  padding-bottom: 10px;
  padding-left: 70px;
  width: 50%;
  text-align: left; }
  div.feedback-map h2 {
    text-align: left !important; }

label {
  font-family: "Cormorant", serif;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  color: #3e3c3a; }

.form-control {
  background-color: #f9f9f9;
  border: 1px solid #dcd6ce;
  outline: none;
  color: #555555;
  box-shadow: none !important; }

.form-control:focus {
  border: 1px solid #3e3c3a;
  outline: 0;
  outline: thin dotted \9; }

/* feedback form bootstrap overwites column space with 30 */
form#feedback [class*="col-"] {
  background-clip: padding-box;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent; }

/* Google Recaptcha */
form#feedback, #content-reservation {
  padding-top: 60px;
  padding-bottom: 50px; }
  form#feedback .button, #content-reservation .button {
    font-family: "Cormorant", serif;
    background-color: #777777;
    color: #fff;
    font-size: 26px;
    width: 250px;
    font-weight: 700;
    line-height: 45px;
    position: relative;
    text-transform: uppercase;
    transition: all 0.3s ease; }
    form#feedback .button:hover, form#feedback .button:focus, form#feedback .button:visited, #content-reservation .button:hover, #content-reservation .button:focus, #content-reservation .button:visited {
      background-color: #cc0000; }

#googleMap {
  padding-top: 0;
  padding-bottom: 0;
  position: relative; }

#googleMap iframe {
  width: 100%;
  border: 0;
  height: 700px; }

/* contact, reservation form message */
.message, .message-error {
  padding: 20px;
  text-align: center;
  background-color: #333;
  color: #fff;
  margin-bottom: 30px; }

/* PAGE RESERVATION */
.reservationText {
  font-family: "Cormorant", serif;
  position: relative;
  text-align: center;
  padding-bottom: 55px;
  font-style: italic; }
  .reservationText p {
    font-size: 20px;
    line-height: 26px;
    color: #443930;
    font-weight: 400;
    margin: 0; }
    .reservationText p a {
      color: #960004;
      text-decoration: underline;
      font-weight: 700; }
      .reservationText p a:hover {
        color: #bb0005; }
    .reservationText p:first-of-type {
      padding-top: 15px; }

#content-reservation {
  overflow: hidden;
  padding-bottom: 50px; }

/* feedback form bootstrap overwites column space with 30 */
form#reservation [class*="col-"] {
  background-clip: padding-box;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent; }

.bootstrap-datetimepicker-widget {
  background-color: white;
  font-family: "Cormorant", serif;
  font-size: 16px; }

/*  xs */
@media (max-width: 767px) {
  div.feedback h2 {
    font-size: 30px;
    line-height: 36px; }
  #googleMap iframe {
    height: 300px; } }

/*  sm */
/*  md */
/*  lg > 1200 */
@media (min-width: 1200px) {
  #content-reservation {
    padding-left: 160px;
    padding-right: 160px; } }

/* Revolution Slider /banner rotator,.../ */
/* banner rotator - revolution slider OLD */
.bannercontainer {
  width: 100% !important;
  position: relative;
  padding: 0; }

.banner-inner {
  width: 100% !important;
  overflow: hidden !important;
  position: relative; }

section#slider {
  overflow: hidden;
  min-height: 1080px;
  border-bottom: 1px solid #fff; }

section#slider .slotholder::after {
  /*    background: -moz-linear-gradient(top,  rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.1) 35%, rgba(0,0,0,0) 65%, rgba(0,0,0,0) 100%); !* FF3.6-15 *!
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.1) 35%,rgba(0,0,0,0) 65%,rgba(0,0,0,0) 100%); !* Chrome10-25, Safari5.1-6 *!
        background: rgba(0,0,0,0.6); !* Safari5.1 *!
        background: linear-gradient(to bottom,  rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.1) 35%,rgba(0,0,0,0) 65%,rgba(0,0,0,0) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#a6000000',GradientType=0 ); !* IE6-9 *!*/
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute; }

section#slider #slider1-inner .slotholder::after {
  background-color: rgba(0, 0, 0, 0.3); }

section#sliderindex {
  margin-bottom: 0; }

section#slider h1 {
  font-family: "Cormorant", serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 60px;
  line-height: 75px;
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  margin: 0;
  text-align: center !important; }

section#slider h2 {
  margin: 0;
  padding-top: 45px;
  font-family: "Cormorant", serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  text-transform: uppercase;
  letter-spacing: -0.025;
  color: #ffffff;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.75); }

/* submenu slider */
div#arrowDownBasic {
  position: absolute;
  bottom: 60px;
  z-index: 9997;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  div#arrowDownBasic a {
    transition: all 0.2s ease;
    height: 59px;
    width: 68px;
    display: inline-block;
    background: transparent url(../img/arrow_down.png) no-repeat center center; }

/* submenu slider */
div#arrowDown {
  position: absolute;
  width: 100%;
  bottom: 70px;
  z-index: 9997;
  text-align: center;
  display: block; }

/* new arrow down only CSS */
.section04 {
  position: relative; }

.section04 a {
  padding-top: 100px;
  color: #fff; }

.section04 a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  -webkit-animation: sdb04 2s infinite;
  animation: sdb04 2s infinite;
  box-sizing: border-box; }

@-webkit-keyframes sdb04 {
  0% {
    -webkit-transform: translate(0, 0); }
  20% {
    -webkit-transform: translate(0, 0); }
  40% {
    -webkit-transform: translate(0, 0); } }

@keyframes sdb04 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  20% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px); }
  40% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

/* logo over slider (compliment) */
div#logo {
  position: absolute;
  width: 100%;
  top: 8px;
  z-index: 9998;
  text-align: center;
  display: block; }

/* Menu button over slider*/
div#menu-button {
  position: absolute;
  left: 0;
  top: 33px;
  z-index: 9998;
  text-align: left;
  display: block; }
  div#menu-button button {
    width: 195px;
    height: 45px;
    background: #362824 url("../img/iconbar.png") no-repeat left 28px center;
    border: 0; }
    div#menu-button button span.txt {
      font-family: "Cormorant", serif;
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 18px;
      padding-left: 18px; }
    div#menu-button button span.right-border {
      position: absolute;
      top: 0;
      right: -10px;
      width: 20px;
      height: 100%;
      background: linear-gradient(45deg, #362824 10px, transparent 0) 0 10px, linear-gradient(135deg, #362824 10px, transparent 0) 0 10px;
      background-repeat: repeat-y;
      background-position: left;
      background-size: 20px 10px; }
    div#menu-button button.is-clicked {
      display: none; }
    div#menu-button button:hover {
      background-color: #302320; }
      div#menu-button button:hover span.right-border {
        background: linear-gradient(45deg, #302320 10px, transparent 0) 0 10px, linear-gradient(135deg, #302320 10px, transparent 0) 0 10px;
        background-repeat: repeat-y;
        background-position: left;
        background-size: 20px 10px; }

/* LENTA FIXED XS MOBILE */
div.lenta-fixed-mobile {
  position: fixed;
  top: 0;
  height: 60px;
  width: 100%;
  background-color: #302320;
  z-index: 9999; }

/* Revolution Slider CAPTION da prenasq na nov red  */
.tp-caption {
  white-space: inherit !important; }

/* Revolution Slider ARROWS overwrite  - s custom kartinka, v podlipite e s css bez kartinka! */
/*
.tparrows.tp-leftarrow:before {
  content: '';
}

.tparrows.tp-rightarrow:before {
  content: '';
}

.tp-leftarrow {
  z-index: 100;
  cursor: pointer;
  background: url(../img/revolutionslider/large_left6.png) no-Repeat top right;
  width: 40px;
  height: 40px;
}

.tp-rightarrow {
  z-index: 100;
  cursor: pointer;
  background: url(../img/revolutionslider/large_right6.png) no-Repeat top left;
  width: 40px;
  height: 40px;
}

.tp-leftarrow:hover {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url(../img/revolutionslider/large_left6.png) no-Repeat bottom right;
  width: 40px;
  height: 40px;
}
.tp-rightarrow:hover {
  z-index: 100;
  cursor: pointer;
  background: url(../img/revolutionslider/large_right6.png) no-Repeat bottom left;
  width: 40px;
  height: 40px;
}*/
/* Massk Triangle */
.mask-triangle {
  position: absolute;
  z-index: 9998;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #edeae1; }

.mask-triangle:before, .mask-triangle:after {
  content: '';
  position: absolute;
  bottom: 100%;
  width: 50%;
  box-sizing: border-box; }

.mask-triangle:before {
  right: 50%;
  border-bottom: 34px solid #fff;
  border-right: 34px solid transparent; }

.mask-triangle:after {
  left: 50%;
  border-bottom: 34px solid #fff;
  border-left: 34px solid transparent; }

/* BULLETS */
.hermes .tp-bullet {
  overflow: hidden;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #FFF;
  transition: background 0.3s ease;
  position: absolute; }

.hermes .tp-bullet:hover {
  background-color: rgba(0, 0, 0, 0.2); }

.hermes .tp-bullet:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  height: 0;
  left: 0;
  width: 100%;
  background-color: #FFF;
  box-shadow: 0 0 1px #FFF;
  transition: height 0.3s ease; }

.hermes .tp-bullet.selected:after {
  height: 100%; }

/*  xs */
@media (max-width: 767px) {
  /* submenu slider */
  /*  div#arrowDownBasic {
      position: absolute;
      bottom: 35px; z-index: 9997;
    }*/
  .innerbanner {
    margin-top: 50px; }
  section#slider h1 {
    font-size: 40px;
    line-height: 50px; }
  section#slider h2 {
    padding-top: 25px;
    font-size: 20px;
    line-height: 24px; }
  .tp-caption {
    min-width: 400px !important; }
  div.lenta-fixed-mobile .logo-mobile {
    text-align: center;
    padding-top: 17px; }
  div.lenta-fixed-mobile .menu-button-mobile {
    position: absolute;
    left: 20px;
    top: 10px;
    z-index: 9999;
    text-align: left;
    display: block; }
    div.lenta-fixed-mobile .menu-button-mobile button {
      width: 50px;
      height: 40px;
      background: #473430 url("../img/iconbar.png") no-repeat 10px center;
      border: 0; }
      div.lenta-fixed-mobile .menu-button-mobile button.is-clicked {
        display: none; } }

/*  sm */
@media (min-width: 767px) and (max-width: 991px) {
  section#slider h1 {
    font-size: 45px;
    line-height: 55px; }
  section#slider h2 {
    padding-top: 35px;
    font-size: 24px;
    line-height: 26px; }
  .tp-caption {
    min-width: 500px !important; } }

/*  md */
@media (min-width: 992px) and (max-width: 1199px) {
  section#slider h1 {
    font-size: 55px;
    line-height: 65px; }
  section#slider h2 {
    padding-top: 40px;
    font-size: 28px;
    line-height: 30px; }
  .tp-caption {
    min-width: 600px !important; } }

/*  lg > 1200 */
@media (min-width: 1200px) {
  .tp-caption {
    /*    min-width: 800px !important;
    width: 100% !important;*/
    min-width: 500px !important;
    left: 50% !important;
    text-align: left !important; }
  section#slider h1 {
    text-align: left !important; } }

/*  lg > 1400 */
/*  lg > 1600 */
.heading-big {
  font-size: 38px !important;
  line-height: 44px !important; }

.heading-medium {
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0; }

.heading-small {
  font-size: 24px !important;
  line-height: 28px !important;
  font-weight: 700;
  margin-bottom: 0; }

.editor-custom-color {
  color: #857070 !important; }

.editor-custom-color2 {
  color: #c81414 !important; }

/*  large devices lg */
/* medium devices md */
/* small devices sm */
@media (min-width: 767px) and (max-width: 991px) {
  body {
    background-image: none; }
    body .wrapper .content-wrapper {
      margin-left: 0; } }

/* mobile xs sm*/
@media (max-width: 767px) {
  body {
    background-image: none; }
    body .wrapper .content-wrapper {
      margin-left: 0; }
  .heading-medium {
    font-size: 28px !important;
    line-height: 36px !important; }
  .heading-medium {
    font-size: 24px !important;
    line-height: 30px !important; }
  .heading-small {
    font-size: 20px !important;
    line-height: 26px !important; } }

/*# sourceMappingURL=app.css.map */
